export enum TypeActionCode{
    CreateInHouse = 0,
    ChangeRate = 1,
    ChangeArrival = 2,
    ChangeDeparture = 3,
    ChangeRoom = 4,
    ChangeRoomType = 5,
    ChangeComment = 6,
    ChangeGuestFirstName = 7,
    ChangeGuestLastName = 8,
    ChangeGuestTitle = 9,
    ChangeCompanyOrAgent = 10,
    ChangeRateCode = 12,
    ChangePaymentMethod = 13,
    UpgradeRoom = 14,
    SetFixedRate = 15,
    SetComplimentary = 16,
    SetHouseUse = 17,
    SetNoPost = 18,
    ChangeDeposit = 19,
    SetPrintRate = 20,
    AddShareGuest = 21,
    Routing = 22,
    Combine = 23,
    Breake = 24,
    Specials = 25,
    LimosineService = 26,
    ChangeFlightArrival = 27,
    ChangeFlightDeparture = 28,
    ChangeAdult = 29,
    ChangeChildren = 30,
    Cancel = 31,
    Active = 32,
    PickUp = 33,
    Confirm = 34,
    UnConfirm = 35,
    NoShow = 36,
    Transfer = 37,
    UnSetFixedRate = 38,
    UnSetComplimentary = 39,
    UnSetHouseUse = 40,
    UnSetDeposit = 42,
    UnSetPrintRate = 43,
    ChangeResType = 44,
    ChangeResSouce = 46,
    ChangeResMarket = 47,
    ChangePackages = 48,
    AssignRoom = 49,
    CheckIn = 50,
    CheckOut = 51,
    CancelCheckIn = 52,
    CancelCheckOut = 53,
    ChangeDiscount = 54,
    ChangeGroupMaster = 55,
    ChangeParentMe = 56,
    ChangeGuestID = 57,
    ChangeGuestPassport = 58,
    ChangeSpecials = 60,
    ChangeCheckInTime = 61,
    ChangeCheckOutTime = 62,
    UnAssignRoom = 63,
    FolioRouting = 64,
    FolioTransfer = 65,
    FolioSplit = 68,
    FolioCombine = 69,
    FolioAlias = 70,
    FolioUndoCombine = 71,
    FolioRebate = 72,
    FolioPayment = 73,
    EnrlyCheckIn = 74,
    EnrlyChekOut = 75,
    ChangeGroupCode = 76,
    SetGroupMaster = 77,
    TransferInRoom = 78,
    RoutingOtherRoom = 79,
    SplitFolio = 80,
    CombineFolio = 81,
    UndoCombine = 82,
    CondenseFolio = 83,
    UndoCondense = 84,
    ChangeGuestNationality = 85,
    UpdateVoucher = 86,
    UpdateDescription = 87,
    ChangeResOrigin = 88,
    ChangeResBookBy = 89,
    ChangeResBookTel = 90,
    ChangeResBookEmail = 91,
    ChangeRateRef = 92,
    SetIsNet = 93,
    TransferDepositToFolio = 94,
    ChangeRoomToClean = 95,
    ChangeRoomToDirty = 96,
    ChangeRateAutoByExchage = 1000,
    ChangeResNumber = 1001,
    ChangeOther = 99,
    ChangeToInspected = 1002,
    ChangeConfirmed = 1003,
    UpdateGuestScanPassport = 1005,
    ChangeResChanel = 1006,
    ChangeToInspected_Unset = 1007,
    FixCharge_Modify = 1008
}
