import { TypeActionCode } from "./enum/tracer.enum"

class Trace{
    static GetActionName(actionCode: number): string{
        switch(actionCode){
        case TypeActionCode.FixCharge_Modify : return "Modify Fix Charge"
        case TypeActionCode.UpdateGuestScanPassport : return "Update Guest By Scan Passport"
        case TypeActionCode.ChangeResChanel : return "Change Reservation Source"
        case TypeActionCode.ChangeConfirmed : return "Change Confirmed"
        case TypeActionCode.ChangeToInspected : return "Change To Inspected"
        case TypeActionCode.ChangeToInspected_Unset: return "Change To Unset Inspected"
        case TypeActionCode.ChangeResNumber : return "Change Res. Number"
        case TypeActionCode.ChangeRateAutoByExchage : return "Auto Update Rate because change exchange rate."
        case TypeActionCode.ChangeRoomToClean : return "Change Room To Clean"
        case TypeActionCode.ChangeRoomToDirty : return "Change Room To Dirty"
        case TypeActionCode.SetIsNet : return "Change Is Net"
        case TypeActionCode.ChangeRateRef : return "Change Rate Reference"
        case TypeActionCode.ChangeResOrigin : return "Change Reservation Origin"
        case TypeActionCode.ChangeResBookBy : return "Change Reservation Booker By"
        case TypeActionCode.ChangeResBookTel : return"Change Reservation Booker Phone"
        case TypeActionCode.ChangeResBookEmail : return "Change Reservation Booker Email"
        case TypeActionCode.ChangeGuestNationality : return "Change Guest Nationality"
        case TypeActionCode.ChangeGroupCode : return "Change Group Code"
        case TypeActionCode.UnAssignRoom : return "Un Assign Room"
        case TypeActionCode.CreateInHouse : return "Create"
        case TypeActionCode.ChangeRate : return "Change Rate"
        case TypeActionCode.ChangeArrival : return "Change Arrival"
        case TypeActionCode.ChangeDeparture : return "Change Departure"
        case TypeActionCode.ChangeRoom : return "Change Room"
        case TypeActionCode.ChangeRoomType : return "Change Room Type"
        case TypeActionCode.ChangeComment : return "Change Comments"
        case TypeActionCode.ChangeGuestFirstName : return "Change Guest Fisrt Name"
        case TypeActionCode.ChangeGuestLastName : return "Change Guest Last Name"
        case TypeActionCode.ChangeGuestTitle : return "Change Guest Title"
        case TypeActionCode.ChangeCompanyOrAgent : return "Change Company Or Agent"
        case TypeActionCode.ChangeRateCode : return "Change Rate Code"
        case TypeActionCode.ChangePaymentMethod : return "Change Payment Method"
        case TypeActionCode.UpgradeRoom : return "Upgarde Room"
        case TypeActionCode.SetFixedRate : return  "Change Fixed Rate"
        case TypeActionCode.SetComplimentary : return "Change Complimentary"
        case TypeActionCode.SetHouseUse : return "Change House Use"
        case TypeActionCode.SetNoPost : return "Change No Post"
        case TypeActionCode.ChangeDeposit : return "Change Deposit"
        case TypeActionCode.SetPrintRate : return "Change Print Rate"
        case TypeActionCode.AddShareGuest : return "Add Share Guest "
        case TypeActionCode.Routing : return "Routing "
        case TypeActionCode.Combine : return "Combine guest "
        case TypeActionCode.Breake : return "Break Guest "
        case TypeActionCode.Specials : return "Change Specials"
        case TypeActionCode.LimosineService : return "Change Limo Service"
        case TypeActionCode.ChangeFlightArrival : return "Change Flight Arrival"
        case TypeActionCode.ChangeFlightDeparture : return "Change Flight Departure"
        case TypeActionCode.ChangeAdult : return "Change Adult"
        case TypeActionCode.ChangeChildren : return "Change Childrent"
        case TypeActionCode.Cancel : return "Cancel"
        case TypeActionCode.Active : return "Active"
        case TypeActionCode.PickUp : return "Pick Up"
        case TypeActionCode.Confirm : return "Confirmed "
        case TypeActionCode.UnConfirm : return "Un Confirm "
        case TypeActionCode.NoShow : return "Set No Show "
        case TypeActionCode.ChangeResType : return "Change Reservations Type"
        case TypeActionCode.ChangeResSouce : return "Change Reservation Source"
        case TypeActionCode.ChangeResMarket : return "Change Reservation Market"
        case TypeActionCode.ChangePackages : return "Change Packages"
        case TypeActionCode.AssignRoom : return "Assign Room"
        case TypeActionCode.CheckIn : return "Check In"
        case TypeActionCode.CheckOut : return "Check Out"
        case TypeActionCode.CancelCheckIn : return "Cancel CheckIn"
        case TypeActionCode.CancelCheckOut : return "Cancel CheckOut"
        case TypeActionCode.ChangeDiscount : return "Change Discount"
        case TypeActionCode.ChangeGroupMaster : return "Change Group Master"
        case TypeActionCode.ChangeParentMe : return "Change Parent Me"
        case TypeActionCode.ChangeGuestID : return "Change Guest ID"
        case TypeActionCode.ChangeGuestPassport : return "Change Guest Passport"
        case TypeActionCode.ChangeSpecials : return "Change Specials"
        case TypeActionCode.ChangeCheckInTime : return "Change CheckIn Time"
        case TypeActionCode.ChangeCheckOutTime : return "Change CheckOut Time"
        case TypeActionCode.FolioRouting : return "Folio: Routing"
        case TypeActionCode.FolioTransfer : return "Folio: Transfer"
        case TypeActionCode.FolioSplit : return "Folio: Split"
        case TypeActionCode.FolioCombine : return "Folio: Combine"
        case TypeActionCode.FolioAlias : return "Folio: Alias"
        case TypeActionCode.FolioUndoCombine : return "Folio: Undo Combine"
        case TypeActionCode.FolioRebate : return "Folio: Rebate"
        case TypeActionCode.FolioPayment : return "Folio: Payment"
        case TypeActionCode.EnrlyCheckIn : return "Enrly Check In"
        case TypeActionCode.EnrlyChekOut : return "Enrly Check Out"
        case TypeActionCode.SetGroupMaster : return "Set Group Master"
        case TypeActionCode.TransferDepositToFolio : return "Transfer Depposit To Folio"
        case TypeActionCode.ChangeOther : return "Change Others"
        }
        return "";
    }
}
export default Trace

