import { NewReservationData } from "common/model-rsvn";
import Utils from "common/utils";

const rSVN_DEFAULT_DATA: NewReservationData = {
    remainRSVN: null,
    dataFotransactRoomDTO: {
        id: 0,
        guestId: null,
        arrivalDate: "null",
        departureDate: "null",
        rate: 0,
        rateCode: null,
        roomType: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        fixedRate: true,
        comments: null,
        flightNo: null,
        printRate: true,
        status: 0,
        flagType: 0,
        cutOfDate: null,
        arrivalPickUp: true,
        departurePickUp: true,
        confirmed: true,
        resSource: null,
        resMarket: null,
        packageCodes: null,
        specialsCodes: null,
        isNet: true,
        guid: null,
        parentGuid: null,
        hotelId: 0,
        hotelGuid: null,
        isNoBkf: true,
        resChanel: null,
        carPickUp: null,
        carPickUpTime: null,
        companyAgentGuid: null,
        nights: 0,
        origin: null,
        resType: null,
        paymentMethod: null,
        nguoiDung2: "",
        nguoiDung: Utils.getValueLocalStorage("idProfile")
    },
    masterGuestGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    roomTypeidnCounts: [],
    dataForeservationDTO: {
        reservationDate: null,
        bookedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        ccno: null,
        ccexpDate: null,
        ccmadeBy: 0,
        comments: null,
        status: 0,
        flagType: 0,
        bookByPhone: null,
        bookByFax: null,
        bookByEmail: null,
        guid: null,
        hotelId: 0,
        hotelGuid: null,
        confirmNum: 0,
        groupName: null,
        groupCode: null
    },
    flyInfor: {
        flyArrival: "null",
        arrival: "2021-03-29T00:00:00",
        flyDeparture: "null",
        departure: "2021-03-29T00:00:00",
        remark: "null",
        hotelGuid: "null"
    },
    pickup: {
        longtitude: 0,
        latitude: 0,
        maDiemDon: "null",
        tenDiemDon: "null",
        dienThoai: "null",
        ghiChu: "null",
        pickUpVehicle: null,
        pickUpTime: "2021-03-29T00:00:00",
        nguoiDung: 0,
        tgtn: "2021-03-29T00:00:00",
        nguoiDung2: 0,
        tgtn2: "2021-03-29T00:00:00",
        isDeleted: true,
        status: 0,
        diemTraKhach: null,
        pickUpVehicleDeparture: null,
        hotelGuid: null
    },
    dataFoextraCharges: [
        {
            parent: 0,
            inHouse: 0,
            taiKhoan: 0,
            maTk: "string",
            tenTk: "string",
            soLuong: 0,
            donGia: 0,
            thanhTien: 0,
            flagType: 0,
            nguoiDung: 0,
            nguoiDung2: 0,
            ghiChu: "string",
            ngayThang: "2021-08-17T07:27:54.882Z",
            kieuNgay: 0,
            tuNgay: 0,
            denNgay: 0,
            autoPost: true,
            hotelGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            dataFoextraChargeDetails: [
                {
                    id: 0,
                    guid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    parent: 0,
                    parentGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    inHouse: 0,
                    inHouseGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    ngayThang: "2021-08-17T07:27:54.882Z",
                    maTk: "string",
                    tenTk: "string",
                    soLuong: 0,
                    donGia: 0,
                    thanhTien: 0,
                    status: 0,
                    nguoiDung: 0,
                    tgtn: "2021-08-17T07:27:54.882Z",
                    nguoiDung2: 0,
                    tgtn2: "2021-08-17T07:27:54.882Z",
                    ghiChu: "string",
                    ngayThu: 0,
                    autoPost: true,
                    tuNgay: 0,
                    denNgay: 0,
                    hotelGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6"
                }
            ]
        }
    ]
};

export default rSVN_DEFAULT_DATA;