import GLobalPkm from "common/global";
import { GuestProfile, GuestProfileNoRsvn } from "common/model-profile";

const GUEST_PROFILE_DEFAULT_VALUE: GuestProfile = {
    id: 0,
    parent: 0,
    guestName: null,
    firstName: null,
    titlesGuid: GLobalPkm.defaultBytes32,
    adress: null,
    zip: null,
    city: null,
    profession: null,
    noOfGuest: 0,
    email: null,
    phone: null,
    languages: null,
    country: 0,
    nationalityGuid: null,
    agentGuid: null,
    companyGuid: null,
    source: null,
    groupsGuest: 0,
    prefLast: null,
    comments: null,
    passport: null,
    visaNumber: null,
    visaExpDate: null,
    entryPort: null,
    entryDate: null,
    adnumber: null,
    ccno: null,
    ccexpDate: null,
    ccmadeBy: 0,
    deposit: 0,
    taxCode: null,
    flagType: 0,
    nguoiDung: 0,
    capDo: 0,
    tgtn: null,
    guestType: null,
    birthDay: null,
    balance: 0,
    idfix: 0,
    guid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    parentGuid: null,
    accountCode: null,
    passportDate: null,
    khachNn: null,
    gioiTinh: null,
    loaiGiayTo: null,
    ngheNghiep: null,
    danToc: null,
    tonGiao: null,
    mucDichLuuTru: null,
    quocGiaNew: null,
    tinh: null,
    huyen: null,
    xa: null,
    soNhaDuong: null,
    hanTamTru: null,
    ghiChu: null,
    vietKieu: null,
    loaiThiThuc: null,
    noiCapThiThiThuc: null,
    lyDoHetHanTamTru: null,
    noiDungLyDoHetHan: null,
    visaDate: null,
    hotelGuid: null,
    statusRec: 0,
    bookingCode: null,
    groupCode: null,
    groupName: null,
}

export const GUEST_PRF_DEFAULT_NORSVN: GuestProfileNoRsvn = {
    parent: 0,
    guestName: null,
    firstName: null,
    titlesGuid: GLobalPkm.defaultBytes32,
    adress: null,
    zip: null,
    city: null,
    profession: null,
    noOfGuest: 0,
    email: null,
    phone: null,
    languages: null,
    country: 0,
    nationalityGuid: null,
    agentGuid: null,
    companyGuid: null,
    source: null,
    groupsGuest: 0,
    prefLast: null,
    comments: null,
    passport: null,
    visaNumber: null,
    visaExpDate: null,
    entryPort: null,
    entryDate: null,
    adnumber: null,
    ccno: null,
    ccexpDate: null,
    ccmadeBy: 0,
    deposit: 0,
    taxCode: null,
    flagType: 0,
    nguoiDung: 0,
    capDo: 0,
    tgtn: null,
    guestType: null,
    birthDay: null,
    balance: 0,
    idfix: 0,
    parentGuid: null,
    accountCode: null,
    passportDate: null,
    khachNn: null,
    gioiTinh: null,
    loaiGiayTo: null,
    ngheNghiep: null,
    danToc: null,
    tonGiao: null,
    mucDichLuuTru: null,
    quocGiaNew: null,
    tinh: null,
    huyen: null,
    xa: null,
    soNhaDuong: null,
    hanTamTru: null,
    ghiChu: null,
    vietKieu: null,
    loaiThiThuc: null,
    noiCapThiThiThuc: null,
    lyDoHetHanTamTru: null,
    noiDungLyDoHetHan: null,
    visaDate: null,
    hotelGuid: null,
}

export default GUEST_PROFILE_DEFAULT_VALUE;